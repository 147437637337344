<template>
  <div>
    <v-row class="pb-8">
      <v-col
        sm="12"
        md="8">
        <CyAlert
          theme="error"
          :content="providerResourcesErrors"/>
        <div class="provider pl-2 mt-6 d-flex align-center">
          <CyIconCredential
            :key="provider.canonical"
            size="48"
            :type="provider.canonical"/>
          <h3 class="provider__title">
            {{ provider.name }}
          </h3>
        </div>
        <div data-cy="select-aws-credential">
          <v-select
            v-model="providerConfig[providerCanonical].credentialCanonical"
            :items="selectedProviderCredentials"
            :label="$t('Credential')"
            required
            item-value="canonical"
            item-text="name"
            clearable
            hide-selected
            persistent-hint
            class="pl-2 mb-2 mt-5 required-field">
            <template #selection="data">
              <v-avatar
                :key="data.item.type"
                class="mr-2"
                size="32">
                <CyIconCredential
                  :type="data.item.type"
                  show-tooltip/>
              </v-avatar>
              <div class="black--text">
                {{ data.item.name }}
              </div>
            </template>

            <template #item="data">
              <v-list-item-avatar>
                <CyIconCredential
                  :type="data.item.type"
                  show-tooltip/>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ data.item.name }}
                </v-list-item-title>
              </v-list-item-content>
            </template>
          </v-select>
        </div>
        <v-autocomplete
          v-if="providerCanonical === 'aws'"
          v-model="providerConfig[providerCanonical].region"
          data-cy="select-aws-region"
          :items="$static.AWS_REGIONS"
          :label="$t('untranslated.region')"
          :hint="$t('hints.region')"
          :filter="filterItems"
          persistent-hint
          required
          class="mb-2 required-field pl-2">
          <template #item="{ item }">
            {{ item.text }} <span class="ml-2 provider__region">{{ item.value }}</span>
          </template>
        </v-autocomplete>

        <v-autocomplete
          v-if="providerCanonical === 'google'"
          v-model="providerConfig[providerCanonical].region"
          :items="$static.GCP_REGIONS"
          :label="$t('untranslated.region')"
          :hint="$t('hints.region')"
          :filter="filterItems"
          persistent-hint
          required
          class="mb-2 required-field pl-2">
          <template #item="{ item }">
            {{ item.text }} <span class="ml-2 provider__region">{{ item.value }}</span>
          </template>
        </v-autocomplete>

        <v-select
          v-if="providerCanonical === 'azurerm'"
          v-model="providerConfig[providerCanonical].environment"
          :items="$static.AZURE_ENVS"
          :label="$t('Environment')"
          :hint="$t('hints.azureEnv')"
          persistent-hint
          required
          class="mb-2 required-field pl-2"/>

        <v-text-field
          v-for="customField of providerCustomFields"
          :key="customField"
          v-model="providerConfig[providerCanonical][customField]"
          :label="$t(`labels.${customField}`)"
          :hint="$te(`hints.${customField}`) ? $t(`hints.${customField}`) : ''"
          :persistent-hint="$te(`hints.${customField}`)"
          required
          class="mb-2 required-field pl-2"/>

        <v-combobox
          v-if="providerCanonical === 'azurerm'"
          v-model="providerConfig[providerCanonical].resourceGroupNames"
          :label="configLabels.resourceGroupNames"
          :hint="$t('hints.resourceGroups')"
          :items="[]"
          append-icon=""
          multiple
          required
          class="mb-2 required-field pl-2">
          <template #selection="{ item }">
            <CyTag
              variant="default"
              class="mr-1"
              icon-after="close"
              @click-icon-after="removeResourceGroup(item)">
              {{ item }}
            </CyTag>
          </template>
        </v-combobox>

        <v-checkbox
          v-for="booleanField of providerBooleanFields"
          :key="booleanField"
          v-model="providerConfig[providerCanonical][booleanField]"
          :label="$t(`labels.${booleanField}`)"
          required
          class="mt-10 mb-2 pl-2"/>
      </v-col>
    </v-row>

    <v-divider/>

    <div class="d-flex justify-end pt-4">
      <CyButton
        variant="secondary"
        theme="primary"
        @click="previousStep">
        {{ $t('forms.btnCancel') }}
      </CyButton>
      <CyButton
        class="ml-4"
        data-cy="configure-provider-btn"
        variant="primary"
        theme="secondary"
        :loading="loading"
        :disabled="$v.$invalid"
        @click="nextStep">
        {{ $t('forms.btnContinue') }}
      </CyButton>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import AWS_REGIONS from '@/utils/config/aws-regions'
import AZURE_ENVS from '@/utils/config/azure-environments'
import GCP_REGIONS from '@/utils/config/gcp-regions'
import { required, url } from 'vuelidate/lib/validators'

export default {
  name: 'CyInfraImportWizardConfigureProvider',
  data: () => ({
    providerConfig: {
      aws: {
        region: null,
        credentialCanonical: null,
      },
      google: {
        region: null,
        project: null,
        credentialCanonical: null,
      },
      azurerm: {
        environment: 'public',
        resourceGroupNames: null,
        credentialCanonical: null,
      },
      vsphere: {
        credentialCanonical: null,
        server: null,
        allowUnverifiedSsl: false,
      },
    },
    loading: false,
  }),
  validations () {
    const isSelectedProviderMatch = (_value, key) =>
      key === this.providerCanonical
    const allConfigs = {
      aws: {
        region: { required },
        credentialCanonical: { required },
      },
      azurerm: {
        environment: { required },
        resourceGroupNames: { required },
        credentialCanonical: { required },
      },
      google: {
        region: { required },
        project: { required },
        credentialCanonical: { required },
      },
      vsphere: {
        credentialCanonical: { required },
        server: {
          required,
          mustBeFQDN: (value) => this.validateFQDN(value),
        },
      },
    }
    return {
      providerConfig: _.pickBy(allConfigs, isSelectedProviderMatch),
    }
  },
  computed: {
    $static: () => ({
      AWS_REGIONS,
      GCP_REGIONS,
      AZURE_ENVS,
      providerCredentialMap: {
        azurerm: ['azure', 'azure_storage'],
        google: 'gcp',
        vsphere: 'vmware',
      },
    }),
    ...mapState('organization', {
      credentials: (state) => state.available.credentials,
    }),
    ...mapState('organization/infraImport', {
      providerCanonical: (state) => state.providerCanonical,
      provider: (state) => state.provider,
      providerResourcesErrors: (state) => state.errors.providerResources,
      stateProviderConfig: (state) => state.providerConfig,
    }),
    configLabels () {
      return {
        project: this.$t('Project'),
        resourceGroupNames: this.$t('labels.resourceGroups'),
      }
    },
    selectedProviderCredentials () {
      const { $static: { providerCredentialMap }, providerCanonical } = this
      const allowedCredentialTypes = _.castArray(providerCredentialMap[providerCanonical])
      return _(this.credentials)
        .filter(({ type }) =>
          type === providerCanonical || allowedCredentialTypes.includes(type))
        .sortBy(({ name }) => _.lowerCase(name))
        .value()
    },
    providerBooleanFields () {
      return _(this.providerConfig[this.providerCanonical])
        .pickBy(_.isBoolean)
        .keys()
        .value()
    },
    providerCustomFields () {
      const exclusions = ['credentialCanonical', 'region', 'environment', 'resourceGroupNames']
      return _(this.providerConfig[this.providerCanonical])
        .omit(exclusions)
        .pickBy(_.negate(_.isBoolean))
        .keys()
        .value()
    },
    providerConfigsMatch () {
      const providerConfigDiff = _.difference(
        Object.keys(this.providerConfig[this.providerCanonical]),
        Object.keys(this.stateProviderConfig),
      )

      return _.$isEmpty(providerConfigDiff)
    },
  },
  watch: {
    selectedProviderCredentials: {
      handler (newVal) {
        if (_.size(newVal) === 1) {
          this.$set(
            this.providerConfig[this.providerCanonical],
            'credentialCanonical',
            newVal[0].canonical,
          )
        }
      },
      deep: true,
    },
  },
  async mounted () {
    this.CLEAR_ERRORS('providerResources')
    await this.GET_PROVIDER_BY_ID({ providerId: this.providerCanonical })

    const { providerCanonical } = this

    if (this.providerConfigsMatch) {
      this.providerConfig[providerCanonical] = _.merge(
        { ...this.providerConfig[providerCanonical] },
        _.cloneDeep(this.stateProviderConfig),
      )
    }
  },
  methods: {
    ...mapActions('organization/infraImport', [
      'GET_PROVIDER_RESOURCES',
      'GET_PROVIDER_BY_ID',
    ]),
    ...mapMutations('organization/infraImport', [
      'NEXT_STEP',
      'PREVIOUS_STEP',
      'SET_PROVIDER_CONFIG',
      'CLEAR_ERRORS',
    ]),
    removeResourceGroup (group) {
      this.$set(this.providerConfig.azurerm, 'resourceGroupNames', _.without(this.providerConfig.azurerm.resourceGroupNames, group))
    },
    previousStep () {
      this.PREVIOUS_STEP()
    },
    async nextStep () {
      const { providerConfig, providerCanonical } = this
      this.loading = true
      this.SET_PROVIDER_CONFIG({ ...providerConfig[providerCanonical] })
      await this.GET_PROVIDER_RESOURCES()
      this.loading = false

      if (!_.$isEmpty(this.providerResourcesErrors)) return
      this.NEXT_STEP()
    },
    filterItems ({ value, text }, queryText) {
      const searchText = queryText.toLowerCase()
      return value.toLowerCase().includes(searchText) || text.toLowerCase().includes(searchText)
    },
    validateFQDN (value) {
      // url() validator accepts only URLs, but FQDN have to be
      // without protocol, so we "fake" by including it
      return url(`http://${value}`)
    },
  },
  i18n: {
    messages: {
      en: {
        labels: {
          allowUnverifiedSsl: 'Allow unverified SSL',
          project: 'Project ID',
          resourceGroups: 'Resource groups',
          server: 'Server',
        },
        hints: {
          azureEnv: 'The Cloud Environment which should be used.',
          region: 'The region infrastructure resources will be imported from',
          resourceGroups: 'One or more resource groups from which resources will be imported. Type in a resource group name then press enter or tab.',
          server: 'FQDN or IP address of the vCenter server.',
        },
      },
      es: {
        labels: {
          allowUnverifiedSsl: 'Permitir SSL no verificado',
          project: 'ID de proyecto',
          resourceGroups: 'Grupos de recursos',
          server: 'Servidor',
        },
        hints: {
          azureEnv: 'Cloud Environment que debe ser usado',
          region: 'Los recursos de infraestructura de la región se importarán desde',
          resourceGroups: 'Uno o más grupos de recursos desde los que se importarán los recursos. Escriba un nombre de grupo de recursos y luego presione enter o tab.',
          server: 'FQDN o dirección IP del servidor vCenter.',
        },
      },
      fr: {
        labels: {
          allowUnverifiedSsl: 'Autoriser SSL non vérifié',
          project: 'ID de projet',
          resourceGroups: 'Groupes de ressources',
          server: 'Serveur',
        },
        hints: {
          azureEnv: `Cloud Environment à utiliser`,
          region: `Les ressources d'infrastructure de la région seront importées depuis`,
          resourceGroups: 'Un ou plusieurs groupes de ressources à partir desquels les ressources seront importées. Saisissez un nom de groupe de ressources, puis appuyez sur Entrée ou sur Tab.',
          server: 'FQDN ou adresse IP du serveur vCenter.',
        },
      },
    },
  },
}
</script>is

<style lang="scss" scoped>
.provider {
  ::v-deep .credential-icon {
    margin-right: 24px;

    @media screen and (width <= 960px) {
      display: none;
    }
  }

  &__title {
    color: cy-get-color("primary");
  }

  &__region {
    color: cy-get-color("grey", "dark-2");
  }
}
</style>
