<template>
  <div
    :class="['provider-container mt-4', {
      'provider-container--loaded': !loading,
    }]">
    <v-progress-circular
      v-if="loading"
      class="ma-auto"
      indeterminate
      color="secondary"/>
    <template
      v-else
      v-for="provider of list('available.providers')">
      <CyInfraImportProviderCard
        v-if="provider.isSupported"
        :key="provider.canonical"
        :provider="provider"
        single-select
        data-cy="choose-provider-btn"
        @selected="selectProvider(provider)"/>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import CyInfraImportProviderCard from '@/components/infra-import/provider-card.vue'

export default {
  name: 'CyInfraImportWizardSelectProvider',
  components: {
    CyInfraImportProviderCard,
  },
  data: () => ({
    loading: true,
  }),
  computed: {
    ...mapGetters('organization/infraImport', [
      'list',
    ]),
    ...mapState('organization/infraImport', {
      stateProviderCanonical: (state) => state.providerCanonical,
    }),
  },
  async mounted () {
    await this.GET_PROVIDERS()
    this.loading = false
  },
  methods: {
    ...mapActions('organization/infraImport', [
      'GET_PROVIDERS',
    ]),
    ...mapMutations('organization/infraImport', [
      'NEXT_STEP',
      'SET_PROVIDER_CANONICAL',
      'RESET_PROVIDER_CONFIG',
    ]),
    selectProvider ({ canonical: providerId }) {
      if (this.stateProviderCanonical !== providerId) this.RESET_PROVIDER_CONFIG()
      this.SET_PROVIDER_CANONICAL(providerId)
      this.NEXT_STEP()
    },
  },
}
</script>

<style lang="scss" scoped>
  .provider-container {
    display: flex;
    flex-flow: row wrap;
    flex-grow: 1;

    &--loaded {
      flex-grow: 0;
      justify-content: center;
      margin: auto -24px;

      > * {
        flex-grow: 0;
        margin: 0 24px 48px;
      }
    }
  }
</style>
