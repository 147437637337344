<template>
  <v-select
    ref="select"
    :menu-props="{ offsetY: true, maxHeight: 308 }"
    :label="label"
    :placeholder="$t('selectAnItem')"
    :return-object="returnObject"
    :loading="fetchInProgress.configRepositories"
    :items="configRepositories"
    item-text="name"
    dense
    hide-selected
    v-bind="$attrs"
    data-cy="config-repo-dropdown"
    @change="$emit('input', $event)"
    @blur="$emit('blur', $event)">
    <template #selection="{ item = {} }">
      <v-list-item>
        <v-list-item-content data-cy="repo-selected">
          <v-list-item-title data-cy="selection-name">
            {{ item.name }}:
          </v-list-item-title>
          <v-list-item-subtitle
            class="d-flex justify-space-between"
            data-cy="selection-url">
            {{ item.url }}
            <CyTag
              v-if="item.default"
              variant="primary"
              small
              class="mr-2"
              data-cy="selection-tag">
              default
            </CyTag>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </template>
    <template #item="{ item = {} }">
      <v-list-item-content data-cy="repo-list-item">
        <v-list-item-title data-cy="item-name">
          {{ item.name }}:
        </v-list-item-title>
        <v-list-item-subtitle
          class="d-flex justify-space-between"
          data-cy="item-url">
          {{ item.url }}
          <CyTag
            v-if="item.default"
            variant="primary"
            small
            data-cy="item-tag">
            default
          </CyTag>
        </v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </v-select>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import i18n from '@/utils/plugins/i18n'

export default {
  name: 'CyInputsConfigRepositorySelect',
  props: {
    autoselectLone: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: () => i18n.t('ConfigRepository'),
    },
  },
  computed: {
    ...mapState('organization', {
      configRepositories: (state) => _.orderBy(state.available.configRepositories, ['default', 'name'], ['desc', 'asc']),
      fetchInProgress: (state) => state.fetchInProgress,
    }),
    returnObject () {
      return this.$attrs['return-object'] ?? true
    },
  },
  watch: {
    configRepositories: {
      handler (newVal) {
        if (this.autoselectLone && _.size(newVal) === 1) {
          this.$emit('input', newVal[0])
        }
      },
      deep: true,
      immediate: true,
    },
  },
  async mounted () {
    if (this.$attrs.items) console.error(`[CyInputsConfigRepositorySelect] Do not bind :items, they're already bound within the component.`)

    if (_.isEmpty(this.configRepositories) && this.$cycloid.permissions.canDisplay('ListConfigRepositories')) {
      await this.FETCH_AVAILABLE({ keyPath: 'configRepositories' })
    }
  },
  methods: {
    ...mapActions('organization', [
      'FETCH_AVAILABLE',
    ]),
  },
  i18n: {
    messages: {
      en: {
        selectAnItem: 'Please select an item',
      },
      es: {
        selectAnItem: 'Por favor seleccione un elemento',
      },
      fr: {
        selectAnItem: 'Veuillez sélectionner une source',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
$v-select-line-height: 19px;

.v-select {
  &__selections {
    flex-wrap: nowrap;
    min-width: 0;

    > * {
      min-width: 0;
    }

    .v-list-item {
      min-height: auto;
      padding: 0;

      &__content {
        flex-flow: row nowrap;
        height: auto;
        padding: 0;
        font-size: $font-size-default;

        .v-list-item {
          &__title {
            flex: 0 0 auto;
            width: auto;
            padding: 0;
            line-height: $v-select-line-height;
            text-overflow: unset;
          }

          &__subtitle {
            padding-left: 4px;
            color: cy-get-color("primary", "light-2");
            font-size: $font-size-default;
            line-height: $v-select-line-height;
          }
        }
      }
    }
  }

  &-list {
    .v-list-item__content {
      .v-list-item {
        &__title {
          font-weight: normal;
          line-height: $v-select-line-height;
          text-overflow: unset;
        }

        &__subtitle {
          color: cy-get-color("primary", "light-2");
          font-size: $font-size-default;
          font-weight: $font-weight-default;
        }
      }
    }
  }
}
</style>
