export default [
  {
    value: 'northamerica-northeast1',
    text: 'Montréal',
  },
  {
    value: 'northamerica-northeast2',
    text: 'Toronto',
  },
  {
    value: 'southamerica-east1',
    text: 'São Paulo',
  },
  {
    value: 'us-central1',
    text: 'Iowa',
  },
  {
    value: 'us-east1',
    text: 'South Carolina',
  },
  {
    value: 'us-east4',
    text: 'N. Virginia',
  },
  {
    value: 'us-west1',
    text: 'Oregon',
  },
  {
    value: 'us-west2',
    text: 'Los Angeles',
  },
  {
    value: 'us-west3',
    text: 'Salt Lake City',
  },
  {
    value: 'us-west4',
    text: 'Las Vegas',
  },
  {
    value: 'europe-central2',
    text: 'Warsaw',
  },
  {
    value: 'europe-north1',
    text: 'Finland',
  },
  {
    value: 'europe-west1',
    text: 'Belgium',
  },
  {
    value: 'europe-west2',
    text: 'London',
  },
  {
    value: 'europe-west3',
    text: 'Frankfurt',
  },
  {
    value: 'europe-west4',
    text: 'Netherlands',
  },
  {
    value: 'europe-west6',
    text: 'Zurich',
  },
  {
    value: 'asia-east1',
    text: 'Taiwan',
  },
  {
    value: 'asia-east2',
    text: 'Hong Kong',
  },
  {
    value: 'asia-northeast1',
    text: 'Tokyo',
  },
  {
    value: 'asia-northeast2',
    text: 'Osaka',
  },
  {
    value: 'asia-northeast3',
    text: 'Seoul',
  },
  {
    value: 'asia-south1',
    text: 'Mumbai',
  },
  {
    value: 'asia-south2',
    text: 'Delhi',
  },
  {
    value: 'asia-southeast1',
    text: 'Singapore',
  },
  {
    value: 'asia-southeast2',
    text: 'Jakarta',
  },
  {
    value: 'australia-southeast1',
    text: 'Sydney',
  },
  {
    value: 'australia-southeast2',
    text: 'Melbourne',
  },
]
