<template>
  <v-list-item
    :ripple="ripple"
    :disabled="disabled"
    class="resource-item"
    @click="$emit('select-entity', resource.canonical)">
    <v-list-item-avatar
      class="resource-item__image align-self-start"
      tile
      :rounded="false">
      <v-img
        contain
        :class="{ 'resource-item__image--disabled': imageDisabled }"
        :src="resource.image"/>
    </v-list-item-avatar>

    <v-list-item-content class="align-self-start">
      <v-list-item-title>
        {{ _.truncate(resource.canonical, { length: 30 }) }}
        <v-icon
          v-if="!disabled"
          small
          class="link-icon mb-2"
          color="primary"
          @click.stop="openDocPage">
          open_in_new
        </v-icon>
      </v-list-item-title>
      <v-list-item-subtitle>{{ _.truncate(resource.short_description, { length: 100 }) }}</v-list-item-subtitle>
    </v-list-item-content>

    <v-list-item-icon
      v-if="!isAutocomplete"
      :class="[{ 'my-5': !active }, 'justify-center align-self-center px-2']">
      <v-icon
        v-if="!disabled && removable"
        data-test-unit="closeIcon"
        small
        @click="$emit('deselect-entity', resource.canonical)">
        close
      </v-icon>
      <CyTooltip left>
        <template #activator="{ on }">
          <div v-on="on">
            <div
              v-if="attrsOnVariable.length"
              class="resource-item__attributes">
              {{ attrsOnVariable.length }}
            </div>
          </div>
        </template>
        <ul
          v-for="(attribute, key) in [...attrsOnVariable].sort()"
          :key="key"
          class="resource-item__attributes-list">
          <li class="resource-item__attributes-list--item">
            {{ attribute }}
          </li>
        </ul>
      </CyTooltip>
    </v-list-item-icon>
    <div class="resource-item__arrow">
      <v-icon
        v-if="active"
        x-large>
        chevron_right
      </v-icon>
    </div>
  </v-list-item>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'CyInfraImportResourceItem',
  props: {
    attrsOnVariable: {
      type: Array,
      default: () => [],
    },
    isAutocomplete: {
      type: Boolean,
      default: false,
    },
    resource: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    removable: {
      type: Boolean,
      default: true,
    },
    active: {
      type: Boolean,
      default: false,
    },
    includeResourcesFilter: {
      type: Boolean,
      default: false,
    },
    ripple: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapState('organization/infraImport', {
      providerCanonical: (state) => state.providerCanonical,
    }),
    imageDisabled () {
      if (this.includeResourcesFilter || this.removable) return false
      return this.disabled ? true : !this.active
    },
  },
  methods: {
    openDocPage () {
      // TODO: FE#6430 this method needs to be improved as it targets only official hashicorp providers
      const { providerCanonical, resource } = this
      const type = resource.canonical.substring(providerCanonical.length + 1)

      window.open(`https://registry.terraform.io/providers/hashicorp/${providerCanonical}/latest/docs/resources/${type}`, '_blank')
    },
  },
}
</script>

<style lang="scss" scoped>
.resource-item {
  position: relative;
  border: none;
  border-bottom: 1px solid cy-get-color("grey", "light-1");

  &__image {
    &--disabled {
      filter: grayscale(1);
    }
  }

  &__attributes {
    padding: 0 6px;
    border-radius: 8px;
    background-color: cy-get-color("secondary");
    color: cy-get-color("white");
  }

  &__attributes-list {
    padding: 0;

    &--item {
      list-style: none;
    }
  }

  &__arrow {
    display: flex;
    position: absolute;
    z-index: 5;
    right: 0;
    align-items: center;
    width: 30px;
    min-width: 30px;

    .v-icon {
      margin-left: 20px;
    }
  }
}
</style>
