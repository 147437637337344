import { render, staticRenderFns } from "./wizard-project-information.vue?vue&type=template&id=1e5377e7&scoped=true"
import script from "./wizard-project-information.vue?vue&type=script&lang=js"
export * from "./wizard-project-information.vue?vue&type=script&lang=js"
import style0 from "./wizard-project-information.vue?vue&type=style&index=0&id=1e5377e7&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1e5377e7",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VCombobox,VDivider,VTextField,VTextarea})
