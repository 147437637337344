<template>
  <div
    ref="wizard"
    class="layout__content">
    <v-stepper
      ref="stepper"
      v-model="currentStep"
      vertical>
      <CyTooltip bottom>
        <template #activator="{ on }">
          <CyButton
            class="reset-button"
            theme="error"
            variant="secondary"
            icon-only
            icon="close"
            sm
            v-on="on"
            @click="() => $toggle.isResetDialogVisible(true)"/>
        </template>
        {{ $t('backToZeroState') }}
      </CyTooltip>

      <v-stepper-step
        ref="chooseProvider"
        :complete="step > $static.steps.chooseProvider"
        :editable="lastActiveStep >= $static.steps.chooseProvider"
        :step="$static.steps.chooseProvider">
        {{ $t('chooseProvider') }}
      </v-stepper-step>

      <v-stepper-content
        :step="$static.steps.chooseProvider">
        <CyInfraImportWizardSelectProvider/>
      </v-stepper-content>

      <v-stepper-step
        ref="configureProvider"
        :complete="step > $static.steps.configureProvider"
        :editable="lastActiveStep >= $static.steps.configureProvider"
        :step="$static.steps.configureProvider">
        {{ $t('configureProvider') }}
      </v-stepper-step>

      <v-stepper-content
        :step="$static.steps.configureProvider">
        <CyInfraImportWizardConfigureProvider
          v-if="step === $static.steps.configureProvider"/>
      </v-stepper-content>

      <v-stepper-step
        ref="filters"
        :complete="step > $static.steps.filters"
        :editable="lastActiveStep >= $static.steps.filters"
        :step="$static.steps.filters">
        {{ $t('infraImportImportFilters') }}
      </v-stepper-step>

      <v-stepper-content
        :step="$static.steps.filters">
        <CyInfraImportWizardImportFilters
          v-if="step === $static.steps.filters"
          @previous="PREVIOUS_STEP"
          @next="(entities, tags) => onNextStep(SET_ENTITIES, { entities, tags })"/>
      </v-stepper-content>

      <v-stepper-step
        ref="variables"
        :complete="step > $static.steps.variables"
        :editable="lastActiveStep >= $static.steps.variables"
        :step="$static.steps.variables">
        {{ $t('infraImportVariables') }}
      </v-stepper-step>

      <v-stepper-content
        :step="$static.steps.variables">
        <CyInfraImportWizardVariables
          v-if="step === $static.steps.variables"
          @previous="PREVIOUS_STEP"
          @next="(variables) => onNextStep(SET_VARIABLES, variables)"/>
      </v-stepper-content>

      <v-stepper-step
        ref="stackDetails"
        :complete="step > $static.steps.stackDetails"
        :editable="lastActiveStep >= $static.steps.stackDetails"
        :step="$static.steps.stackDetails">
        {{ $t('infraImportStackInformation') }}
      </v-stepper-step>

      <v-stepper-content
        :step="$static.steps.stackDetails">
        <CyWizardStackDetails
          wizard
          @previous="PREVIOUS_STEP"
          @next="(stack) => onNextStep(SET_STACK, stack)"/>
      </v-stepper-content>

      <template v-if="!wizardConfig.createStackOnly">
        <v-stepper-step
          ref="projectInfo"
          :complete="step > $static.steps.projectInfo"
          :editable="lastActiveStep >= $static.steps.projectInfo"
          :step="$static.steps.projectInfo">
          {{ $t('infraImportProjectInformation') }}
        </v-stepper-step>

        <v-stepper-content
          :step="$static.steps.projectInfo">
          <CyInfraImportWizardProjectInformation
            @previous="PREVIOUS_STEP"
            @next="(project) => onNextStep(SET_PROJECT, project)"/>
        </v-stepper-content>

        <v-stepper-step
          ref="externalBackend"
          :complete="step > $static.steps.externalBackend"
          :editable="lastActiveStep >= $static.steps.externalBackend"
          :step="$static.steps.externalBackend">
          <span>
            {{ $t('infraImportExternalBackendConfiguration') }}
          </span>
        </v-stepper-step>

        <v-stepper-content
          class="external-backend"
          :step="$static.steps.externalBackend">
          <CyInfraImportWizardExternalBackend
            v-if="step === $static.steps.externalBackend"
            @previous="PREVIOUS_STEP"
            @next="(eb) => onNextStep(SET_EXTERNAL_BACKEND_CONFIG, eb)"/>
        </v-stepper-content>
      </template>

      <v-stepper-step
        :ref="wizardConfig.createStackOnly ? 'summaryStack' : 'summaryProject'"
        :complete="wizardConfig.createStackOnly ? step > $static.steps.summaryStack : step > $static.steps.summaryProject"
        :editable="wizardConfig.createStackOnly ? lastActiveStep >= $static.steps.summaryStack : lastActiveStep >= $static.steps.summaryProject"
        :step="wizardConfig.createStackOnly ? $static.steps.summaryStack : $static.steps.summaryProject">
        <span>{{ $t('Summary') }}</span>
      </v-stepper-step>

      <v-stepper-content
        :step="wizardConfig.createStackOnly ? $static.steps.summaryStack : $static.steps.summaryProject">
        <CyInfraImportWizardSummary
          @previous="PREVIOUS_STEP"
          @start-infra-import="START_INFRA_IMPORT"/>
      </v-stepper-content>
    </v-stepper>
    <CyModal
      v-if="isResetDialogVisible"
      :header-title="$t('resetWizardProgress')"
      :cancel-btn-func="() => isResetDialogVisible = false"
      :action-btn-func="RESET_STATE"
      :action-btn-text="$t('forms.btnReset')"
      action-btn-icon=""
      modal-type="delete"
      small>
      <p>
        <b>{{ $t('allProgressLost') }}</b><br>
        {{ $t('forms.areYouSure') }}
      </p>
    </CyModal>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import CyInfraImportWizardConfigureProvider from '@/components/infra-import/wizard-configure-provider.vue'
import CyInfraImportWizardExternalBackend from '@/components/infra-import/wizard-external-backend.vue'
import CyInfraImportWizardImportFilters from '@/components/infra-import/wizard-import-filters.vue'
import CyInfraImportWizardProjectInformation from '@/components/infra-import/wizard-project-information.vue'
import CyInfraImportWizardSelectProvider from '@/components/infra-import/wizard-select-provider.vue'
import CyInfraImportWizardSummary from '@/components/infra-import/wizard-summary.vue'
import CyInfraImportWizardVariables from '@/components/infra-import/wizard-variables.vue'
import CyWizardStackDetails from '@/components/wizard/stack-details.vue'

export default {
  name: 'CyInfraImportWizard',
  components: {
    CyInfraImportWizardSelectProvider,
    CyInfraImportWizardConfigureProvider,
    CyInfraImportWizardProjectInformation,
    CyInfraImportWizardImportFilters,
    CyInfraImportWizardVariables,
    CyWizardStackDetails,
    CyInfraImportWizardSummary,
    CyInfraImportWizardExternalBackend,
  },
  data: () => ({
    isResetDialogVisible: false,
  }),
  computed: {
    ...mapState('organization/infraImport', {
      step: (state) => state.step,
      wizardConfig: (state) => state.wizardConfig,
      lastActiveStep: (state) => state.lastActiveStep,
    }),
    currentStep: {
      get () {
        return this.step
      },
      set (step) {
        this.SET_STEP(step)
      },
    },
    $static: () => ({
      steps: {
        chooseProvider: 1,
        configureProvider: 2,
        filters: 3,
        variables: 4,
        stackDetails: 5,
        projectInfo: 6,
        externalBackend: 7,
        summaryStack: 6,
        summaryProject: 8,
      },
    }),
  },
  watch: {
    async step (step) {
      const currentStepName = _.entries(this.$static.steps).find((stepAndId) => stepAndId[1] === step)[0]
      const currentStep = _.get(this.$refs, `${currentStepName}.$el`)

      if (currentStep) {
        await this.$nextTick()
        await _.$pause(650)
        const top = currentStep.getBoundingClientRect().top + window.pageYOffset - 64

        window.scrollTo({ top, behavior: 'smooth' })
      }
    },
  },
  methods: {
    ...mapActions('organization/infraImport', [
      'START_INFRA_IMPORT',
    ]),
    ...mapMutations('organization/infraImport', [
      'NEXT_STEP',
      'PREVIOUS_STEP',
      'RESET_STATE',
      'SET_ENTITIES',
      'SET_EXTERNAL_BACKEND_CONFIG',
      'SET_PROJECT',
      'SET_STACK',
      'SET_STEP',
      'SET_STEP',
      'SET_VARIABLES',
    ]),
    onNextStep (mutation, data) {
      mutation(_.cloneDeep(data))
      this.NEXT_STEP()
    },
  },
  i18n: {
    messages: {
      en: {
        allProgressLost: 'All @:InfraImport wizard data will be cleared',
        backToZeroState: 'Close the wizard',
        chooseProvider: 'Choose Provider',
        configureProvider: 'Configure Provider',
        infraImportExternalBackendConfiguration: 'External Backend Configuration',
        infraImportImportFilters: '@:InfraImport filters',
        infraImportProjectInformation: 'Project Information',
        infraImportStackInformation: 'Stack Information',
        infraImportVariables: '@:InfraImport variables',
        optional: '(optional)',
        resetWizardProgress: 'Reset wizard progress',
        startAllOver: 'Start all over',
      },
      es: {
        allProgressLost: 'Se borrarán todos los datos del asistente de @:InfraImport',
        backToZeroState: 'Cerrar el asistente',
        chooseProvider: 'Elegir provider',
        configureProvider: 'Configurar Provider',
        infraImportExternalBackendConfiguration: 'Configuración del Backend Externo',
        infraImportImportFilters: 'Filtros de @:InfraImport',
        infraImportProjectInformation: 'Información del proyecto',
        infraImportStackInformation: 'Información de la stack',
        infraImportVariables: 'Variables @:InfraImport',
        optional: '(opcional)',
        resetWizardProgress: 'Restablecer el progreso del asistente',
        startAllOver: 'Comenzar de nuevo',
      },
      fr: {
        allProgressLost: `Toutes les données de l'assistant @:InfraImport seront effacées`,
        backToZeroState: `Fermer l'assistant`,
        chooseProvider: 'Choisir provider',
        configureProvider: 'Configurer Provider',
        infraImportExternalBackendConfiguration: 'Configuration du Backend Externe',
        infraImportImportFilters: 'Filtres de @:InfraImport',
        infraImportProjectInformation: 'Renseignements sur le projet',
        infraImportStackInformation: 'Informations sur la stack',
        infraImportVariables: 'Variables @:InfraImport',
        optional: '(facultatif)',
        resetWizardProgress: `Réinitialiser la progression de l'assistant`,
        startAllOver: 'Tout recommencer',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .v-stepper_header {
  display: flex;
  flex-wrap: nowrap;
  justify-content: left;
  overflow: auto;
}

::v-deep .v-stepper__wrapper {
  margin-left: -12px;
  padding-left: 12px;
}

.external-backend {
  ::v-deep .v-stepper__wrapper {
    height: auto !important;
  }
}

.reset-button {
  position: absolute;
  z-index: 100;
  top: 16px;
  right: 16px;
}
</style>
