export default [
  {
    value: 'public',
    text: 'Public (default)',
  },
  {
    value: 'usgovernment',
    text: 'US Government',
  },
  {
    value: 'german',
    text: 'German',
  },
  {
    value: 'china',
    text: 'China',
  },
]
