<template>
  <v-data-table
    v-bind="$attrs"
    :id="tableId"
    class="cy-data-table elevation-2"
    hide-default-footer
    :headers="headers"
    :items="items"
    :options.sync="options">
    <template #item="props">
      <tr class="cy-data-table__row">
        <slot
          v-bind="{ props }"
          bodyrow
          name="table-cmp-body-row">
          <td
            v-for="(header, index) in headers"
            :key="index"
            class="text-left">
            {{ props.item[header.value] }}
          </td>
        </slot>
      </tr>
    </template>
    <template #footer>
      <CyDataTablePagination
        v-if="items.length"
        class="v-data-footer px-4 py-1"
        :items-length="items.length"
        :items-per-page-options="itemsPerPageOptions"
        :options.sync="options"/>
    </template>
    <template slot="no-data"/>
  </v-data-table>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
import CyDataTablePagination from '@/components/data-table/pagination.vue'

export default {
  name: 'CyDataTable',
  components: {
    CyDataTablePagination,
  },
  props: {
    tableId: {
      type: String,
      default: 'cy-data-table',
    },
    headers: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    itemsPerPageOptions: {
      type: Array,
      default: () => [10, 20, 30, 50],
    },
    itemsPerPage: {
      type: Number,
      default: 10,
    },
  },
  computed: {
    ...mapGetters('layout', [
      'getDataTableProps',
    ]),
    options: {
      get () {
        return { itemsPerPage: this.itemsPerPage, ...this.getDataTableProps(this.$route.name) }
      },
      set ({ itemsPerPage, page }) {
        const { name } = this.$route
        this.SET_DATA_TABLE_PROPS({ name, props: { itemsPerPage, page } })
      },
    },
  },
  methods: {
    ...mapMutations('layout', [
      'SET_DATA_TABLE_PROPS',
    ]),
  },
}
</script>

<style lang="scss" scoped>
.cy-data-table {
  border-radius: 4px;

  ::v-deep th:first-child {
    padding-left: 66px;
  }
}
</style>
