var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cy-combobox"},[_c('v-combobox',_vm._b({ref:"combobox",class:['cy-combobox__input', {
      'cy-combobox__input--dropdown-hidden': !_vm.isDropdownEnabled,
      'required-field': _vm.required,
    }],attrs:{"value":_vm.value,"error-messages":_vm.errorMessages,"hint":_vm.hint,"persistent-hint":!_vm._.isEmpty(_vm.hint),"search-input":_vm.searchInputBridge,"multiple":_vm.multiple,"hide-selected":""},on:{"update:searchInput":function($event){_vm.searchInputBridge=$event},"update:search-input":function($event){_vm.searchInputBridge=$event},"change":function (items) { return _vm.$emit('change', items); },"input":function (items) { return _vm.$emit('input', items); },"focus":function($event){return _vm.$emit('focus')},"blur":function($event){return _vm.$emit('blur')}},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_vm._t("selection",function(){return [_c('CyTag',{key:((JSON.stringify(data.item)) + "-" + (data.index)),staticClass:"cy-combobox__selection mr-1",attrs:{"is-selected":data.selected,"variant":"primary","icon-after":!_vm.disabled ? 'close' : '',"small":_vm.small,"input-value":data.selected,"focusable":_vm.focusableItems,"disabled":data.disabled},on:{"click-icon-after":function($event){return _vm.removeItem(data.item)}}},[_vm._v(" "+_vm._s(data.item)+" ")])]},null,data.item)]}},{key:"item",fn:function(ref){
    var item = ref.item;
return [_vm._t("item",function(){return [_c('CyTag',{staticClass:"cy-combobox__selection mr-1",attrs:{"variant":"primary","small":_vm.small}},[_vm._v(" "+_vm._s(item)+" ")])]},null,{ item: item })]}},{key:"message",fn:function(ref){
    var message = ref.message;
return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.$sanitizeHtml(message))}})]}}],null,true)},'v-combobox',_vm.$attrs,false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }