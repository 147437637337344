<template>
  <div class="d-flex flex-column justify-start">
    <div
      v-for="(value, key) of item"
      :key="key"
      class="item-row d-flex flex-row">
      <span class="value--primary font-weight-bold pr-2">
        {{ $t(key) }}
      </span>
      <span class="d-flex flex-row">
        <span
          v-if="String(key).includes('provider')"
          class="d-flex flex-row value--secondary">
          <CyIconCredential
            :type="providerAbbreviation.toLowerCase()"/>
          {{ value }}
        </span>
        <span
          v-else-if="String(key).includes('environment')"
          class="value--secondary">
          {{ item.environment }}
        </span>
        <span
          v-else-if="String(key).includes('configuration')"
          class="value--secondary">
          <div
            v-for="([configKey, configValue]) in Object.entries(value)"
            :key="configKey">
            <span class="value--primary">{{ configKey }}: </span>
            <span class="value--secondary">{{ configValue }}</span>
          </div>

        </span>
        <span
          v-else
          class="value--secondary">
          {{ value }}
        </span>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CyInfraImportItemDetails',
  props: {
    item: {
      type: Object,
      default: null,
    },
    providerAbbreviation: {
      type: String,
      default: '',
    },
  },
  i18n: {
    messages: {
      en: {
        allowUnverifiedSsl: 'Allow unverified SSL',
        canonical: 'Project canonical:',
        configRepository: 'Config repository:',
        config_repository_canonical: 'Config repository canonical:',
        configuration: 'Configuration',
        credentialCanonical: 'Credentials:',
        credential_canonical: 'Credentials:',
        description: 'Description:',
        environment: 'Environment:',
        features: 'Features:',
        metadata_host: 'Metadata host:',
        name: 'Project name:',
        provider: 'Provider:',
        region: 'Region:',
        resourceGroupNames: 'Resource group names',
        server: 'Server',
      },
      es: {
        allowUnverifiedSsl: 'Permitir SSL no verificado',
        canonical: 'Canónico del proyecto:',
        configRepository: 'Repositorio de configuración:',
        config_repository_canonical: 'Canónico del repositorio de configuración:',
        configuration: 'Configuración',
        credentialCanonical: 'Credenciales:',
        credential_canonical: 'Credenciales:',
        description: 'Descripción:',
        environment: 'Entorno:',
        features: 'Características:',
        metadata_host: 'Host de metadatos:',
        name: 'Nombre del proyecto:',
        provider: 'Proveedor:',
        region: 'Región:',
        resourceGroupNames: 'Nombres de grupos de recursos',
        server: 'Servidor',
      },
      fr: {
        allowUnverifiedSsl: 'Autoriser SSL non vérifié',
        canonical: 'Projet canonique:',
        configRepository: 'Source de configuration:',
        config_repository_canonical: 'Source de configuration canónico:',
        configuration: 'Configuration',
        credentialCanonical: 'Crédits:',
        credential_canonical: 'Crédits:',
        description: 'La description:',
        environment: 'Environnement:',
        features: 'Fonctionnalités:',
        metadata_host: 'Hôte de métadonnées:',
        name: 'Nom du projet:',
        provider: 'Pournisseur:',
        region: 'Région:',
        resourceGroupNames: 'Noms des groupes de ressources',
        server: 'Serveur',
      },
    },
  },
}
</script>

<style scoped lang="scss">
.item-row {
  min-height: 32px;

  .value {
    &--primary {
      color: cy-get-color("primary");
    }

    &--secondary {
      color: map.get($grey, "dark-2");
    }

    &--code {
      white-space: pre-wrap;
    }
  }

  &__environment-tag {
    margin-top: -3px;
  }
}
</style>
