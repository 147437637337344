<template>
  <div>
    <v-container
      class="mb-4">
      <v-row>
        <v-col
          cols="4">
          <CyInfraImportIncludeResourcesFilter
            :loading="loadingProviderResources"
            :selected-entities="selectedEntities"
            @select-resource="setResource"
            @select-category="setCategory"/>
        </v-col>
        <v-col
          cols="8"
          data-cy="infraimport-tags">
          <CyInputsCombobox
            v-model="tags"
            class="import-filters__tags pt-0"
            :small="false"
            chips
            :is-dropdown-enabled="false"
            :placeholder="$t('untranslated.tags')"
            :hint="$t('tagsHint')"
            @input="filterResourcesByTags"/>
        </v-col>
      </v-row>
      <v-row class="import-filters__list-headers">
        <v-col
          class="pb-0"
          cols="4">
          <div>{{ $t('Resources') }}</div>
        </v-col>
        <v-col
          class="pb-0"
          cols="8">
          <div>{{ $t('terracost.resource.id') }}</div>
        </v-col>
      </v-row>
      <v-row class="import-filters__row">
        <v-col cols="4">
          <div :class="['elevation-2 import-filters__column', { 'import-filters__column--zero-state': !hasSelectedEntities }]">
            <v-list class="import-filters__list">
              <CyInfraImportResourceItem
                v-for="resource in entities"
                :ref="resource.canonical"
                :key="resource.canonical"
                data-cy="import-filter-element"
                :resource="resource"
                :removable="hasSelectedEntities"
                :disabled="!hasSelectedEntities"
                @deselect-entity="deselectEntity"/>
            </v-list>
            <div
              v-if="!hasSelectedEntities"
              class="text-center my-3">
              <span class="import-filters__missing-resource">{{ $t('missingResource') }}</span>
              <a
                class="import-filters__contact-us"
                href="https://www.cycloid.io/contact-us"
                target="blank">
                {{ $t('contactUs') }}
              </a>
            </div>
          </div>
        </v-col>
        <v-col cols="8">
          <div :class="['elevation-2', 'import-filters__column', { 'd-flex justify-center align-center': !hasSelectedEntities }]">
            <v-list
              v-if="hasSelectedEntities"
              class="import-filters__list">
              <v-list-item
                v-for="{ canonical } in selectedEntities"
                ref="resource-ids-item"
                :key="canonical"
                :class="[
                  { 'align-end px-0': loading[canonical] },
                  { 'align-start': !loading[canonical] },
                  { 'align-center justify-center': _.$isEmpty(resourceIds[canonical]) && !loading[canonical] },
                  'import-filters__list-item']">
                <v-progress-linear
                  v-if="loading[canonical]"
                  :active="loading[canonical]"
                  indeterminate/>
                <div
                  v-else-if="_.$isEmpty(resourceIds[canonical])"
                  class="import-filters__list-item--empty">
                  {{ $t('noResources') }}
                </div>
                <CyTagList
                  v-else
                  class="py-2 import-filters__tag-list"
                  contained
                  :tags="resourceIds[canonical]"
                  small
                  @tag-list-toggled="changeResourceItemHeight($event, canonical)">
                  <template #tag="{ tag }">
                    <CyTooltip
                      bottom
                      :disabled="tag.id.length < 40">
                      <template #activator="{ on }">
                        <CyTag
                          v-bind="$attrs"
                          variant="primary"
                          small
                          v-on="on">
                          {{ _.truncate(tag.id, { length: 40 }) }}
                        </CyTag>
                      </template>
                      {{ tag.id }}
                    </CyTooltip>
                  </template>
                </CyTagList>
              </v-list-item>
            </v-list>
            <div
              v-else
              class="d-flex flex-column align-center justify-center import-filters__zero-state">
              <v-img
                class="import-filters__zero-state-image"
                :width="240"
                :height="240"
                :src="$static.zeroStateIcon"/>
              <p class="import-filters__zero-state-description">
                {{ $t('importFiltersZeroState') }}
              </p>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row
        class="import-filters__clear-all"
        no-gutters>
        <v-col
          class="d-flex justify-end"
          cols="4">
          <a
            v-if="hasSelectedEntities"
            class="cy-link mr-4 mt-2"
            @click="clearSelectedEntities">
            {{ $t('forms.clearAll') }}
          </a>
        </v-col>
        <v-col cols="8"/>
      </v-row>
    </v-container>

    <v-divider/>

    <div class="d-flex justify-end pt-4">
      <slot name="actions">
        <CyButton
          variant="secondary"
          @click="$emit('previous')">
          {{ $t('forms.back') }}
        </CyButton>

        <CyButton
          data-cy="include-resources-btn"
          @click="$emit('next', selectedEntities, tags)">
          {{ $t('forms.btnContinue') }}
        </CyButton>
      </slot>
      <div/>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import CyInfraImportIncludeResourcesFilter from '@/components/infra-import/include-resources-filter.vue'
import CyInfraImportResourceItem from '@/components/infra-import/resource-item.vue'
import CyInputsCombobox from '@/components/inputs/combobox.vue'
import CyTagList from '@/components/tag-list.vue'

export default {
  name: 'CyInfraImportWizardImportFilters',
  components: {
    CyInfraImportIncludeResourcesFilter,
    CyInfraImportResourceItem,
    CyInputsCombobox,
    CyTagList,
  },
  data: () => ({
    selectedEntities: [],
    tags: [],
    loading: {},
  }),
  computed: {
    $static: () => ({
      zeroStateIcon: '/static/images/AWS_General_flat.png',
    }),
    ...mapState('organization/infraImport', {
      stateEntities: (state) => state.entities,
      resourceIds: (state) => state.resourceIds,
      loadingProviderResources: (state) => state.loading.providerResources,
      stateTags: (state) => state.tags,
    }),
    ...mapGetters('organization/infraImport', [
      'list',
    ]),
    hasSelectedEntities () {
      return !_.$isEmpty(this.selectedEntities)
    },
    entities () {
      return this.hasSelectedEntities
        ? this.selectedEntities
        : [..._(this.list('available.resources')).sortBy(['category']).value()]
    },
  },
  mounted () {
    const { stateEntities, stateTags } = this

    if (!_.isEmpty(stateEntities)) this.selectedEntities = _.cloneDeep(stateEntities)
    if (!_.isEmpty(stateTags)) this.tags = _.cloneDeep(stateTags)
  },
  methods: {
    ...mapActions('organization/infraImport', [
      'GET_PROVIDER_RESOURCE_IDS',
    ]),
    async setResource (resourceCanonical) {
      const resource = _.find(this.list('available.resources'), (resource) => resourceCanonical === resource.canonical)
      this.selectedEntities.push(resource)

      await this.fetchResourceIDs(resourceCanonical)
    },
    async setCategory (resourceCanonicals) {
      const resources = _.filter(this.list('available.resources'), ({ canonical }) => resourceCanonicals.includes(canonical))

      this.selectedEntities = _.uniqBy([
        ...this.selectedEntities,
        ...resources,
      ], 'canonical')

      const getResourceIdsPromises = _.map(resourceCanonicals, async (resourceCanonical) => await this.fetchResourceIDs(resourceCanonical))
      await Promise.allSettled(getResourceIdsPromises)
    },
    async fetchResourceIDs (resourceCanonical) {
      this.$set(this.loading, resourceCanonical, true)
      await this.GET_PROVIDER_RESOURCE_IDS({ resourceCanonical, tags: this.tags })
      this.$set(this.loading, resourceCanonical, false)
    },
    deselectEntity (entityCanonical) {
      this.selectedEntities = _.filter(this.selectedEntities, ({ canonical }) => canonical !== entityCanonical)
    },
    clearSelectedEntities () {
      this.selectedEntities = []
    },
    changeResourceItemHeight ({ expanded, height }, canonical) {
      const el = this.$refs[canonical]

      expanded
        ? el[0].$el.style.setProperty('min-height', `${height}px`, 'important')
        : el[0].$el.style.removeProperty('min-height')
    },
    async filterResourcesByTags () {
      const promises = _.map(this.selectedEntities, async ({ canonical: resourceCanonical }) => {
        this.$set(this.loading, resourceCanonical, true)

        await this.GET_PROVIDER_RESOURCE_IDS({ resourceCanonical, tags: this.tags })
        this.$set(this.loading, resourceCanonical, false)
      })

      await Promise.allSettled(promises)
    },
  },
  i18n: {
    messages: {
      en: {
        contactUs: 'Contact us',
        importFiltersZeroState: 'By default, @:InfraImport will import all the supported resource types from your cloud provider. You can narrow the import results with tags and/or include specific resources.',
        missingResource: 'Missing resource?',
        noResources: 'No resources found',
        tagsHint: 'List of tags to filter by with \'NAME:VALUE\' format. Resource has to match all the provided tags to be included. Resources that don\'t support tags won\'t be affected by tags filtering.',
      },
      es: {
        contactUs: 'Contacta con nosotros',
        importFiltersZeroState: 'Por defecto, @:InfraImport importará todos los tipos de recursos soportados por su proveedor en la nube. Puede limitar los resultados de la importación con etiquetas y / o incluir recursos específicos.',
        missingResource: '¿Falta un recurso?',
        noResources: 'No se encontraron recursos',
        tagsHint: 'Lista de tags por las que filtrar con el formato \'NOMBRE:VALOR\'. El recurso debe coincidir con todas las tags proporcionadas para ser incluido. Los recursos que no admiten tags no se verán afectados por el filtrado de tags.',
      },
      fr: {
        contactUs: 'Nous contacter',
        importFiltersZeroState: 'Par défaut, @:InfraImport importera tous les types de ressources pris en charge par votre fournisseur de cloud. Vous pouvez affiner les résultats de l\'import avec des tags et / ou inclure des ressources spécifiques.',
        missingResource: 'Ressource manquante ?',
        noResources: 'Aucune ressource trouvée',
        tagsHint: 'Liste des tags à filtrer au format \'NOM:VALEUR\'. La ressource doit correspondre à toutes les tags fournies pour être incluses. Les ressources qui ne prennent pas en charge les tags ne seront pas affectées par le filtrage des tags.',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.import-filters {
  &__list-headers {
    margin-bottom: 0;
    color: cy-get-color("grey", "dark-2");
    font-size: 1rem;
  }

  &__row {
    height: 60vh;
    min-height: 437px;
    margin-top: 0;
    overflow-y: auto;
  }

  &__column {
    min-height: 100%;
    border-radius: 4px;

    &--zero-state {
      max-height: 386px;
      overflow-y: auto;
    }
  }

  &__list {
    padding: 0;
    border-radius: 4px;
  }

  &__list-item,
  .v-list-item {
    min-height: 58px;
    padding: 0 8px;
    border-bottom: 1px solid cy-get-color("grey", "light-1");
  }

  &__list-item--empty {
    color: cy-get-color("grey", "dark-2");
  }

  &__zero-state {
    max-width: 300px;
    color: cy-get-color("grey", "dark-2");
  }

  &__zero-state-image {
    max-width: 240px;
    margin-top: -24px;
  }

  &__zero-state-description {
    margin-top: -32px;
    text-align: center;
  }

  &__clear-all {
    min-height: 29px;
  }

  &__contact-us {
    color: cy-get-color("secondary");
    font-weight: $font-weight-bolder;
  }

  &__missing-resource {
    color: cy-get-color("grey", "dark-2");
  }

  &__tag-list {
    overflow: auto;
  }

  &__tags {
    ::v-deep .v-text-field {
      padding-top: 2px !important;
    }

    ::v-deep .v-select__selections input {
      font-size: 14px;
    }
  }
}
</style>
